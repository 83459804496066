// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import TabButton from './UI/Button';
// import Search from './UI/SearchFunctionality/Search';
// import { setSelectedButton, showContent } from '../../redux/Buyreducer';
// import './footer.css';

// const FilterPage = () => {
//   const dispatch = useDispatch();
//   const selectedButton = useSelector((state) => state.filter.selectedButton);
//   const contentVisible = useSelector((state) => state.filter.contentVisible);

//   React.useEffect(() => {
//     // Set default button to "Buy" in the UI but dispatch "Sell"
//     if (!selectedButton) {
//       dispatch(setSelectedButton('Sell'));
//     }
//     dispatch(showContent());
//   }, [dispatch, selectedButton]);

//   const handleOptionClick = (displayOption) => {
//     // Dispatch "Sell" if "Buy" is clicked, or dispatch "Buy" if "Rent" is clicked
//     const dispatchOption = displayOption === 'Buy' ? 'Sell' : displayOption;
//     dispatch(setSelectedButton(dispatchOption));
//     dispatch(showContent());
//   };

//   const renderContent = () => {
//     switch (selectedButton) {
//       case 'Sell':
//       case 'Rent':
//         return (
//           <div className="content-container">
//             <Search />
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="text-text1 hidden md:block -mt-5">
//       <div className=" h-5 font-thirdCustom flex justify-start items-center   ">
//         <div className="flex  justify-start bg-white rounded-t-lg shadow-lg ">
//           {['Buy', 'Rent'].map((button) => (
//             <TabButton
//               key={button}
//               className={`uppercase flex p-2 text-[#2E4374] font-[400] rounded-lg items-center ${
//                 (button === 'Buy' && selectedButton === 'Sell') || selectedButton === button
//                   ? 'bg-gray-200 font-light text-[18px] p-2'
//                   : 'bg-white rounded-t-lg text-[18px] p-2'
//               }`}
//               onSelect={() => handleOptionClick(button)}
//             >
//               <span className='text-[#2E4374] font-[400] uppercase font-thirdCustom'>{button}</span>
//             </TabButton>
//           ))}
//         </div>
//       </div>
//       {contentVisible && <div className="content-wrapper">{renderContent()}</div>}
//     </div>
//   );
// };

// export default FilterPage;


import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TabButton from './UI/Button';
import Search from './UI/SearchFunctionality/Search';
import { setSelectedButton, showContent } from '../../redux/Buyreducer';
import './footer.css';

const FilterPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedButton = useSelector((state) => state.filter.selectedButton);
  const contentVisible = useSelector((state) => state.filter.contentVisible);

  React.useEffect(() => {
    if (!selectedButton) {
      dispatch(setSelectedButton('Sell'));
    }
    dispatch(showContent());
  }, [dispatch, selectedButton]);

  const handleOptionClick = (displayOption) => {
    const dispatchOption = displayOption === 'Buy' ? 'Sell' : displayOption;
    dispatch(setSelectedButton(dispatchOption));
    dispatch(showContent());
  };

  const renderContent = () => {
    switch (selectedButton) {
      case 'Sell':
      case 'Rent':
        return (
          <div className="content-container">
            <Search />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="text-text1 hidden md:block -mt-5">
      <div className="h-5 font-thirdCustom flex justify-start items-center">
        <div className="flex justify-start bg-white rounded-t-lg shadow-lg">
          {['Buy', 'Rent'].map((button) => (
            <TabButton
              key={button}
              className={`uppercase flex p-2 text-[#2E4374] font-[400] rounded-lg items-center ${
                (button === 'Buy' && selectedButton === 'Sell') || selectedButton === button
                  ? 'bg-gray-200 font-light text-[18px] p-2'
                  : 'bg-white rounded-t-lg text-[18px] p-2'
              }`}
              onSelect={() => handleOptionClick(button)}
            >
              <span className="text-[#2E4374] font-[400] uppercase font-thirdCustom text-[#737579]">
                {t(button.toLowerCase())}
              </span>
            </TabButton>
          ))}
        </div>
      </div>
      {contentVisible && <div className="content-wrapper">{renderContent()}</div>}
    </div>
  );
};

export default FilterPage;


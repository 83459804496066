import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Thunk to fetch property details
export const fetchPropertyDetails = createAsyncThunk(
  'propertyDetails/fetchPropertyDetails',
  async (property_id) => {
    const response = await fetch(`https://square-bric-backend-api.squarebric.com/property-search/property-details?property_id=${property_id}`);
    const data = await response.json();
    console.log('Fetched Data property:', data); // Logs the entire response
    return data; // Return the full response data
  }
);

// Create a slice for property details
const propertyDetailsSlice = createSlice({
  name: 'propertyDetails',
  initialState: {
    propertyDetails: null,
    location: null,
    amenities: null,
    imageDetails: null,
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPropertyDetails.fulfilled, (state, action) => {
        state.loading = false;
        // Extract and store different parts of the data
        state.propertyDetails = action.payload.property_details;
        state.location = action.payload.location;
        state.amenities = action.payload.amenities;
        state.imageDetails = action.payload.ImageDetails;
      })
      .addCase(fetchPropertyDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default propertyDetailsSlice.reducer;


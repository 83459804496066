import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import homebirla from '../../../src/assets/homeBirla.svg';
import prime from '../../../src/assets/prime.svg';
import agent1 from '../../assets/Agent2.png';

const staticAgents = [
  {
    agent_id: 'Sq1234567',
    image: 'https://squarebric-media-files.squarebric.com/AgentPic.png',
    name: 'John Doe',
    builder_name: 'Brila builders.',
    website: 'http://example.com',
    professional_achievement: 'Top Sales Agent of 2023',
    area_of_expertise: ['Residential , Commercial ,luxury homes...etc'],
    member: 'Our prime member',
    date: 'operating since 2003'
  },
  {
    agent_id: '2',
    image: agent1,
    name: 'Jane Smith',
    builder_name: 'Another Builder',
    website: 'http://example.com',
    professional_achievement: 'Best Customer Service',
    area_of_expertise: ['Luxury Homes', 'Investment Properties'],
    date: 'operating since 2003',
    member: 'our prime member'
  }
];

const MyAgents = () => {
  const [showAllAgents, setShowAllAgents] = useState(false);
  const navigate = useNavigate();

  const allagent = () => {
    navigate('/moreagents');
  };

  return (
    <div className=" border-2  border-gray-300 p-5 mt-6  ">
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-1 gap-2 ">
        {staticAgents.slice(0, showAllAgents ? staticAgents.length : 2).map((agent) => (
          <div key={agent.agent_id} className="">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-2">
              <div className="">
                <img src={agent.image} alt="AgentPic" className=" w-[100%] h-[100%]" />
              </div>

              <div className="">
                <h2 className="text-lg text-[#5B8BC5] font-[500] text-start font-fifth">{agent.name}</h2>
                <p className="text-[#818EAE] font-thirdCustom font-[400] text-sm">ID: {agent.agent_id}</p>
                <p className="text-sm text-[#D2A254] font-[500] text-start font-thirdCustom flex justify-start gap-1">
                  <img src={prime} alt="prime" /> {agent.member}
                </p>
                <p className=" text-[#5B8BC5]  font-fifth underline font-[500]   mt-2">
                  <img src={homebirla} alt="homebirla" className="w-4 h-4" />
                  {agent.builder_name}
                </p>
                <p>{agent.date}</p>
                <p className="text-gray-600 text-sm flex gap-1 mt-2">
                  <a href={agent.website} className="text-blue-600 -mt-1 text-[13px]">
                    {agent.website}
                  </a>
                </p>
              </div>
            </div>
            <div style={{ background: 'rgba(30, 40, 88, 0.05)' }} className="mt-6 p-2">
              <div>
                <h3 className="text-lg text-[#5B8BC5] font-fifth font-[500] text-start">About agent</h3>
                <p className="text-[#818EAE] font-thirdCustom font-[400] text-sm ">has 100+ property sold </p>
                <p className="text-[#818EAE] font-thirdCustom font-[400] text-sm">
                  most demanding agent of the locality
                </p>
                <p className="text-[#818EAE] font-thirdCustom font-[400] text-sm">certified by indian government</p>
              </div>
              <p className="mt-6">
                <span className="text-lg text-[#5B8BC5] font-[500] text-start font-fifth ">
                  Professional Achievement:
                </span>
                <p className="text-[#818EAE] font-thirdCustom font-[400] text-sm">{agent.professional_achievement}</p>
              </p>
              <div className="mt-6">
                <h3 className="text-lg text-[#5B8BC5] font-[500] text-start font-fifth">Area of Expertise</h3>
                <div className="text-[#818EAE] font-thirdCustom font-[400] text-sm">
                  {agent.area_of_expertise.map((expertise, index) => (
                    <div key={index}>{expertise}</div>
                  ))}
                </div>
                <Link to={`/agentprofile/${agent.agent_id}`} className="mt-4 flex justify-center items-center">
                  <button className="px-4 py-2 mt-2 text-[#2E4374] bg-[#E2E8F0] rounded hover:bg-gray-200">
                    View Profile
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!showAllAgents && (
        <div className="flex justify-center">
          <button
            onClick={() => setShowAllAgents(true)}
            className="mt-6 px-6 py-2 text-white bg-[#2E4374] hover:bg-[#203256] transition-colors duration-300"
          >
            Show All Agents
          </button>
        </div>
      )}
    </div>
  );
};

export default MyAgents;

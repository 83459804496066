import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CloseIcon from '@mui/icons-material/Close';
import OtpForm from './Otpfarm'; // Import the OtpForm component

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    userType: '',
    name: '',
    email: '',
    phoneNumber: '',
    countryCode: '+91',
    password: '',
    confirmPassword: '',
    agreedToTerms: false
  });

  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false); // State for OTP modal

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, agreedToTerms: e.target.checked });
  };

  const handleUserTypeChange = (type) => {
    setFormData({ ...formData, userType: type });
  };

  const validateForm = () => {
    const newErrors = {};

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Phone number validation regex (10 digits)
    const phoneRegex = /^\d{10}$/;

    // Password validation regex
    // Enforces: between 8 and 14 characters, at least one letter, one digit, and one special character (@#$%*^)
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@#$%*^])[A-Za-z\d@#$%*^]{8,14}$/;

    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    } else if (!phoneRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Phone number must be 10 digits';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        'Password must be between 8 and 14 characters, include at least one letter, one digit, and one special character (@#$%*^)';
    }
    // Confirm Password validation
    if (formData.confirmPassword !== formData.password) {
      newErrors.confirmPassword = 'Passwords must match';
    }
    if (!formData.agreedToTerms) newErrors.terms = 'You must agree to the terms and conditions';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted:', formData);
      // Perform the form submission, API request, etc.
    }
  };

  const handleSendOtp = () => {
    setShowOtpModal(true); // Show OTP modal
  };

  return (
    <div className=" relative ">
      <img
        src={'https://d34yb5orp91dp6.cloudfront.net/login7.png'}
        alt="loginimage"
        className={`absolute top-20 right-0 w-[20%] h-auto`}
      />

      <div className="flex justify-center items-center min-h-screen pt-28">
        <form className="bg-white shadow-md  rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md" onSubmit={handleSubmit}>
          <h2 className="text-center   lg:text-[20px] md:text-[20px] sm:text-[16px] xl:text-[20px] font-[400] leading-normal text-[#2E4374] font-SecondCustom capitalize mb-4">
            Sign Up
          </h2>

          {/* Dynamic Radio Buttons for User Type */}
          <div className="mb-6 ">
            <p className="xs:text-[14px] sm:text-[14px] text-start xl:text-[16px] lg:text-[16px] 2xl-[16px] font-[400] leadiing-[19px] capitalize tracking-negative-0.16 text-[#8C97A7] font-thirdCustom mb-2">
              I am
            </p>
            <div className="flex justify-between space-x-2">
              {['Buyer', 'Agent', 'Builder'].map((type) => (
                <label key={type} className="flex items-center space-x-2">
                  <input
                    type="radio"
                    name="userType"
                    value={type}
                    checked={formData.userType === type}
                    onChange={() => handleUserTypeChange(type)}
                    className="form-radio text-blue-500"
                  />
                  <span className="xs:text-[14px] sm:text-[14px] xl:text-[16px] lg:text-[16px] 2xl-[16px] font-[400] leadiing-[19px] pt-2 capitalize tracking-negative-0.16 text-[#8C97A7] font-thirdCustom mb-2">
                    {type}
                  </span>
                </label>
              ))}
            </div>
            {errors.userType && <p className="text-red-500 text-xs mt-1">{errors.userType}</p>}
          </div>

          {/* Name Field */}
          <div className="mb-4">
            <label
              className="block text-[#20206F] font-roboto text-[12px] font-[400] leading-[18.619px] tracking[-0.081px] capitalize mb-2"
              htmlFor="name"
            >
              Name *
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Name"
            />
            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
          </div>

          {/* Email Field */}
          <div className="mb-4">
            <label
              className="block text-[#20206F] font-roboto text-[12px] font-[400] leading-[18.619px] tracking[-0.081px] capitalize mb-2"
              htmlFor="email"
            >
              Email *
            </label>
            <div className="relative">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Email"
              />
              <button
                type="button"
                onClick={handleSendOtp} // Open OTP modal on click
                className="absolute inset-y-0 right-0 px-4 py-2 bg-[#3C8E51] text-[12px] text-white rounded-r"
              >
                Send OTP
              </button>
            </div>
            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
          </div>

          {/* Phone Number Field with Country Code */}
          <div className="mb-4">
            <label
              className="block text-[#20206F] font-roboto text-[12px] font-[400] leading-[18.619px] tracking[-0.081px] capitalize mb-2"
              htmlFor="phoneNumber"
            >
              Phone Number *
            </label>
            <div className="flex">
              <select
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                className="shadow appearance-none border rounded-l py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="+91">+91 (India)</option>
                <option value="+1">+1 (USA)</option>
                <option value="+44">+44 (UK)</option>
                <option value="+61">+61 (Australia)</option>
                <option value="+971">+971 (UAE)</option>
                {/* Add more country codes as needed */}
              </select>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(e) => {
                  // Allow only numeric values and up to 10 digits
                  const value = e.target.value;
                  if (/^\d{0,10}$/.test(value)) {
                    setFormData({ ...formData, phoneNumber: value });
                  }
                }}
                className="shadow appearance-none border rounded-r w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Phone Number"
              />
            </div>
            {errors.phoneNumber && <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>}
          </div>

          {/* Create Password Field */}
          <div className="mb-4">
            <label
              className="block text-[#20206F] font-roboto text-[12px] font-[400] leading-[18.619px] tracking[-0.081px] capitalize mb-2"
              htmlFor="password"
            >
              Create Password *
            </label>
            <div className="relative">
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Create Password"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 px-2 flex items-center"
              >
                {showPassword ? (
                  <VisibilityOff className="h-5 w-5 text-gray-600" />
                ) : (
                  <Visibility className="h-5 w-5 text-gray-600" />
                )}
              </button>
            </div>
            {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
          </div>

          {/* Confirm Password Field */}
          <div className="mb-6">
            <label
              className="block text-[#20206F] font-roboto text-[12px] font-[400] leading-[18.619px] tracking[-0.081px] capitalize mb-2"
              htmlFor="confirmPassword"
            >
              Confirm Password *
            </label>
            <div className="relative">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Confirm Password"
              />
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute inset-y-0 right-0 px-2 flex items-center"
              >
                {showConfirmPassword ? (
                  <VisibilityOff className="h-5 w-5 text-gray-600" />
                ) : (
                  <Visibility className="h-5 w-5 text-gray-600" />
                )}
              </button>
            </div>
            {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword}</p>}
          </div>

          {/* Terms and Conditions Checkbox */}
          <div className="mb-6  ">
            <input
              type="checkbox"
              id="terms"
              name="agreedToTerms"
              checked={formData.agreedToTerms}
              onChange={handleCheckboxChange}
              className="form-checkbox h-5 w-5  text-blue-500"
            />
            <label
              htmlFor="terms"
              className="ml-2 text-[#9A9A9A] relative bottom-1 font-thirdCustom text-[13.94px] font-[400] leading-[119.231%] tracking-[0.139px] lowercase"
            >
              I agree to the terms and conditions
            </label>
            {errors.terms && <p className="text-red-500 text-xs mt-1">{errors.terms}</p>}
          </div>

          {/* Submit Button */}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className=" bg-[#2e4374] lg:text-[16px] md:text-[16px] sm:text-[14px] xl:text-[16px] font-[600] leading-normal text-white font-SecondCustom capitalize w-[100%] py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Sign Up
            </button>
          </div>
        </form>

        {/* OTP Modal */}
        {showOtpModal && (
          <div className="fixed inset-0 flex items-center justify-center  bg-gray-800 bg-opacity-50 z-10">
            <div className="bg-white rounded-lg shadow-lg p-6 w-96">
              <div className="flex justify-between">
                <h3 className="text-[16px] font-[500] mb-4">Enter OTP</h3>
                <button
                  onClick={() => setShowOtpModal(false)}
                  className=" bg-red-500 text-white font-[200] text-[10px] w-6 h-6 rounded"
                >
                  <CloseIcon />
                </button>
              </div>
              <OtpForm />
            </div>
          </div>
        )}
      </div>
      <img
        src={'https://d34yb5orp91dp6.cloudfront.net/login6.png'}
        alt="logonimage"
        className="absolute bottom-[-56px] left-0  w-[20%] h-auto"
      />
    </div>
  );
};

export default SignUpForm;

import React from 'react';
import { useLocation } from 'react-router-dom';
import usePropertyDetails from './usePropertyDetails';
import Aboutproject from '../../page/SearchProperty/Aboutproject';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import StarRateIcon from '@mui/icons-material/StarRate';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalMallIcon from '@mui/icons-material/LocalMall';
const PropertyDetailShow = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const propertyId = queryParams.get('propertyId');

  const { properties, loading, error } = usePropertyDetails(propertyId);

  if (loading) return <div></div>;
  if (error) return <div>Error loading property details: {error}</div>;
  if (!properties) return <div>No property data available</div>;

  const { property_details, location, amenities, ImageDetails, date_of_listing } = properties;

  const superAreaValue = property_details?.area?.measurement?.[0]?.super_area?.value || 'N/A';
  const superAreaUnit = property_details?.area?.measurement?.[0]?.super_area?.unit || 'N/A';
  const totalArea = property_details?.area?.total_area || [];

  return (
    <div className="border-dotted border-2 border-blue-500 p-2">
      <div>
        {ImageDetails?.default && ImageDetails.default.length > 0 && (
          <img src={`https://d34yb5orp91dp6.cloudfront.net/${ImageDetails.default[0]}`} alt="Property" />
        )}
      </div>
      <div>
        <p className="text-[#1E2858] text-[20px] mt-2 leading-[25px] font-[400] capitalize">
          {property_details?.title || 'N/A'}
        </p>
        <p className="text-[#1E2858] text-[16px] mt-2 leading-[14px] font-[400] capitalize">
          {property_details?.description || 'N/A'}
        </p>
        <p className="text-[#4BA3DB] text-[16px] mt-4 leading-[14px] font-[400] capitalize">
          <strong>Apartment|</strong> By {property_details?.agent_name || 'N/A'}
        </p>
      </div>

      <div className="bg-[#F2FAFE] mt-4 p-2 grid grid-cols-3">
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Owner Type:</strong> {property_details?.owner_type || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Purpose:</strong> {property_details?.purpose || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Property Type:</strong> {property_details?.property_type || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Sub Property Type:</strong> {property_details?.sub_property_type || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Super Area:</strong> {superAreaValue} {superAreaUnit}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Bathrooms:</strong> {property_details?.bathrooms || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Bedrooms:</strong> {property_details?.bedroom_number || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Furnished Status:</strong> {property_details?.furnished_status || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] font-[400] font-thirdCustom p-1">
          <strong>Kitchen:</strong> {property_details?.kitchen || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Living Room:</strong> {property_details?.living_room || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Hall:</strong> {property_details?.hall || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Dining Room:</strong> {property_details?.dining_room || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Availability:</strong> {property_details?.availability?.availability_month || 'N/A'}{' '}
          {property_details?.availability?.availability_year || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Address:</strong> {location?.address || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>City:</strong> {location?.city || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>State:</strong> {location?.state || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Country:</strong> {location?.country || 'N/A'}
        </p>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-1">
          <strong>Zipcode:</strong> {location?.zipcode || 'N/A'}
        </p>
        {/* <p>
          <strong>Total Area:</strong>
        </p>
        <ul>
          {totalArea.map((area, index) => (
            <li key={index}>
              {Object.keys(area)[0]}: {Object.values(area)[0]}
            </li>
          ))}
        </ul> */}
      </div>

      <div className="mt-4">
        <ul className="grid grid-cols-2 gap-2">
          {amenities?.facilities &&
            Object.entries(amenities.facilities).map(([key, value]) => (
              <li
                key={key}
                className="capitalize text-[#1E2858] text-[14px] leading-[15px] font-[400] font-thirdCustom p-2"
              >
                {key}: {value ? 'Yes' : 'No'}
              </li>
            ))}
        </ul>

        <ul className="grid grid-cols-2  gap-2">
          {amenities?.parking &&
            Object.entries(amenities.parking).map(([key, value]) => (
              <li
                key={key}
                className="capitalize text-[#1E2858] text-[14px] leading-[15px] font-[400] font-thirdCustom p-2"
              >
                {key}: {value ? 'Yes' : 'No'}
              </li>
            ))}
        </ul>

        <ul className="grid grid-cols-2  gap-2">
          {amenities?.options &&
            Object.entries(amenities.options).map(([key, value]) => (
              <li
                key={key}
                className="capitalize text-[#1E2858] text-[14px] leading-[15px] font-[400] font-thirdCustom p-2"
              >
                {key}: {value ? 'Yes' : 'No'}
              </li>
            ))}
        </ul>

        <ul className="grid grid-cols-2  gap-2">
          {amenities?.near_localities &&
            Object.entries(amenities.near_localities).map(([key, value]) => (
              <li
                key={key}
                className="capitalize text-[#1E2858] text-[14px] leading-[15px] font-[400] font-thirdCustom p-2"
              >
                {key}: {value ? 'Yes' : 'No'}
              </li>
            ))}
        </ul>

        <ul className="grid grid-cols-2  gap-2">
          {amenities?.property_features &&
            Object.entries(amenities.property_features).map(([key, value]) => (
              <li
                key={key}
                className="capitalize text-[#1E2858] text-[14px] leading-[15px] font-[400] font-thirdCustom p-2"
              >
                {key}: {value ? 'Yes' : 'No'}
              </li>
            ))}
        </ul>
      </div>

      <div>
        <p className="text-[#818EAE] leading-[15px] text-[13px] capitalize font-[400] font-thirdCustom p-2">
          <strong>Date of Listing:</strong> {new Date(date_of_listing).toLocaleDateString()}
        </p>
      </div>
      <div className="w-full p-2 h-auto">
        <div
          className="bg-[#2e4374] text-white mt-4 m-4 p-4"
          style={{ borderBottomLeftRadius: '20%', borderTopRightRadius: '20%' }}
        >
          <div className="flex flex-col lg:flex-row justify-between p-4 font-SecondCustom font-semibold text-lg">
            <h1 className="font-fifth text-xl md:text-xl sm:text-sm font-[600]">About Project</h1>
            <h1 className="mt-4 md:mt-0 font-fifth text-xl md:text-xl sm:text-sm font-[600] hidden lg:block">
              Explore Project
              <TrendingFlatIcon />
            </h1>
          </div>
          <div className="flex flex-col lg:flex-row p-4 items-start">
            <img
              src="https://d34yb5orp91dp6.cloudfront.net/buildinghome.svg"
              alt="bed"
              className="mr-2 bg-[#E0E8FA] mb-4 md:mb-0"
              style={{ borderBottomLeftRadius: '50%' }}
              width={80}
            />
            <div class="w-full  ">
              <div class="flex-1 justify-between text-center ">
                <h1 class="font-fifth text-base sm:text-sm xs:text-xs xs:ml-20 lg:text-left lg:mt-0 md:-mt-20 sm:-mt-24 xs:-mt-24 lg:ml-0 md:-ml-6 sm:ml-0">
                  UNMISSABLE OFFER QUEEN BOMONTI RESIDENCE
                </h1>
                <h1 className="mt-4 md:mt-0 font-fifth text-xl md:text-xl sm:text-sm font-[600] lg:hidden block md:-ml-10 sm:mr-40 xs:mr-0 xs:ml-20">
                  Explore Project
                  <TrendingFlatIcon />
                </h1>
              </div>

              <div className="flex jsustify-between mt-5">
                <div className="flex flex-col">
                  <button class="border-2  font-sixth  rounded-md ">Follow Project</button>
                  <button class="border-2 mt-2 rounded-md px-2">Compare Projects</button>
                </div>
                <span class="hidden md:block md:ml-4 border-l-2 border-white p-1 h-16"></span>

                <div className="flex flex-col">
                  <button class="border-2  rounded-md font-sixth ">View Map</button>
                  <button class="border-2 mt-2 font-sixth rounded-md px-2">Call for price</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-2">
        <h1 className="text-[#1E2858] font-[500] font-thirdCustom text-[15px] mb-2">
          About Dubai Damac Lagoon 992521{' '}
        </h1>
        <p className="text-yellow-400 p-2">
          <span className="text-[#1E2858] font-[500] font-thirdCustom text-[15px] ml-2">4.1</span>
          <StarRateIcon />
          <StarRateIcon />
          <StarRateIcon />
          <StarRateIcon />
          <StarRateIcon />
          1.5k reviews
        </p>
        <p className="p-2">
          Rank 52
          <span className="text-[#1E2858] font-[500] font-thirdCustom text-[15px] ml-3">out of 917 localities</span>
        </p>
        <span className="text-[#1E2858] font-[500] font-thirdCustom mt-2 text-[15px]">Evironment</span>
        <div className="relative p-2 h-2 w-full bg-gray-200 rounded-[10px]">
        <p className="absolute top-0 left-0 h-full w-1/2 bg-yellow-300 rounded-[10px]"></p>
  
     
        </div>
        <span className="text-[#1E2858] font-[500] mt-2 font-thirdCustom text-[15px]">Commuting</span>
        <div className="relative mt-2 p-2 h-2 w-full bg-gray-200 rounded-[10px]">
     
          <p className="absolute top-0 left-0 h-full w-1/2 bg-yellow-300 rounded-[10px]"></p>
        </div>
        <span className="text-[#1E2858] font-[500] font-thirdCustom mt-2 text-[15px]">Place of interest</span>
        <div className="relative p-2 h-2 mt-2 w-full  bg-gray-200 rounded-[10px]">
        
          <p className="absolute top-0 left-0 h-full w-1/2 bg-yellow-300 rounded-[10px]"></p>
        </div>

        <div className="p-2">
          <p className="p-2 mt-4">
            {' '}
            Ensure that the API endpoint and token are correctly set up. Check the network tab in your browser's
            developer tools to see if the API request is being made and if there are any errors in the response.
          </p>
          <button className="text-[#1E2858] font-[500] text-center font-thirdCustom text-[15px]">
            Compare Localities
          </button>
        </div>

        <div className="mt-4">
          <h1 className="text-[#1E2858] font-[500] font-thirdCustom text-[15px]">Near by Atraction</h1>
          <div className="grid grid-cols-4 gap-2 mt-4">
            <LocalAirportIcon />
            <BeachAccessIcon />
            <LocationOnIcon />
            <LocalMallIcon /> 
          </div>
          <div className="grid grid-cols-4 gap-2">
            <span className="text-[#2E4374] font-[400] font-thirdCustom text-[15px] leading-[18px]">Airport</span>
            <span className="text-[#2E4374] font-[400] font-thirdCustom text-[15px] leading-[18px]">BeachAccess</span>
            <span className="text-[#2E4374] font-[400] font-thirdCustom text-[15px] leading-[18px]">
              Nearest Location
            </span>
            <span className="text-[#2E4374] font-[400] font-thirdCustom text-[15px] leading-[18px]">Famous Mall</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailShow;

// // src/i18n.js
// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

// // Import translation files
// import enTranslations from './locales/en/translation.json';
// import trTranslations from './locales/tr/translation.json';

// i18n
//   .use(initReactI18next) // Passes i18n down to react-i18next
//   .init({
//     resources: {
//       en: { translation: enTranslations },
//       tr: { translation: trTranslations },
//     },
//     lng: 'en', // Default language
//     fallbackLng: 'en', // Fallback language
//     interpolation: {
//       escapeValue: false, // React already escapes by default
//     },
//   });

// export default i18n;



import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import enTranslations from './locales/en/translation.json';
import trTranslations from './locales/tr/translation.json';
import ckbTranslations from './locales/ckb/translation.json'; // Add Sorani Kurdish

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: enTranslations },
      tr: { translation: trTranslations },
      ckb: { translation: ckbTranslations }, // Add Sorani Kurdish
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;


import React, { useState, useEffect } from 'react';
import Allfilter from '../SelectFilter/Allfilter';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import StateAndCity from './StateAndCity';
import AdvanceFilter from '../AdvanceFilter';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';



const headerStyle = {
  color: 'white',
  background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))',
  transition: 'all 0.3s ease'
};

const buildQuery = (params) => {
  let queryParams = [];
  if (params.selectedCountry) queryParams.push(`country=${encodeURIComponent(params.selectedCountry)}`);
  if (params.selectedState) queryParams.push(`state=${encodeURIComponent(params.selectedState)}`);
  if (params.selectedCity) queryParams.push(`sub_city=${encodeURIComponent(params.selectedCity)}`);
  if (params.purpose) queryParams.push(`purpose=${encodeURIComponent(params.purpose)}`);
  if (params.propertyType) queryParams.push(`property_type=${encodeURIComponent(params.propertyType)}`);
  if (params.currency) queryParams.push(`currency=${encodeURIComponent(params.currency)}`);
  if (params.subPropertyType && params.subPropertyType.length > 0) {
    params.subPropertyType.forEach((type) => {
      queryParams.push(`sub_property_type=${encodeURIComponent(type)}`);
    });
  }
  if (params.selectedMin != null) queryParams.push(`min_price=${encodeURIComponent(params.selectedMin)}`);
  if (params.selectedMax != null) queryParams.push(`max_price=${encodeURIComponent(params.selectedMax)}`);
  if (params.min_total_area) queryParams.push(`min_total_area=${encodeURIComponent(params.min_total_area)}`);
  if (params.max_total_area) queryParams.push(`max_total_area=${encodeURIComponent(params.max_total_area)}`);
  if (params.selectedAreaOption) queryParams.push(`area_unit=${encodeURIComponent(params.selectedAreaOption)}`);
  if (params.min_Bathrooms) queryParams.push(`min_bathrooms=${encodeURIComponent(params.min_Bathrooms)}`);
  if (params.max_Bathrooms) queryParams.push(`max_bathrooms=${encodeURIComponent(params.max_Bathrooms)}`);
  if (params.min_Bedrooms) queryParams.push(`min_bedrooms=${encodeURIComponent(params.min_Bedrooms)}`);
  if (params.max_Bedrooms) queryParams.push(`max_bedrooms=${encodeURIComponent(params.max_Bedrooms)}`);
  queryParams.push(`skip=${params.skip || 0}`);
  queryParams.push(`limit=${params.limit || 500}`);
  return `https://square-bric-backend-api.squarebric.com/property-search/property-search?${queryParams.join('&')}`;
};

const Search = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const selectedCountry = useSelector((state) => state.location.selectedCountry);
  const selectedState = useSelector((state) => state.location.selectedState);
  const selectedCity = useSelector((state) => state.location.selectedCity);
  const purpose = useSelector((state) => state.filter.selectedButton);
  // console.log("selectedbuttonpankaj", purpose)
  const propertyType = useSelector((state) => state.properties.propertyType);
  const subPropertyType = useSelector((state) => state.properties.subPropertyType);
  let { selectedMin, selectedMax, selectedCurrency } = useSelector((state) => state.multiselect);
  const min_total_area = useSelector((state) => state.selectedPrice.min) || '';
  const max_total_area = useSelector((state) => state.selectedPrice.max) || '';
  const min_Bathrooms = useSelector((state) => state.bathroom.minValue);
  const max_Bathrooms = useSelector((state) => state.bathroom.maxValue);
  const min_Bedrooms = useSelector((state) => state.bedroom.minValue);
  const max_Bedrooms = useSelector((state) => state.bedroom.maxValue);
  console.log('selectedmin', selectedMin);
  console.log('selectedMax', selectedMax);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const selectedAreaOption = useSelector((state) => state.area.selectedAreaOption);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const isValid = selectedCountry && selectedState && selectedCity && propertyType && subPropertyType.length > 0;
    setIsButtonDisabled(!isValid);
  }, [selectedCountry, selectedState, selectedCity, propertyType, subPropertyType]);


  
  const handleSearch = () => {
    if (!selectedState) {
      setAlertMessage('Please select a state before proceeding');
      setAlertOpen(true);
      return;
    }

    if (!selectedCity) {
      setAlertMessage('Please select a city before proceeding');
      setAlertOpen(true);
      return;
    }

    if (!propertyType) {
      setAlertMessage('Please select a category before proceeding');
      setAlertOpen(true);
      return;
    }
    console.log('12345', subPropertyType);

    if (!subPropertyType) {
      setAlertMessage('Please select a type of property before proceeding');
      setAlertOpen(true);
      console.log('12345', subPropertyType);
      return;
    }

    let currency = selectedCurrency.label === 'Dollar' ? 'usd' : selectedCurrency.label;
    console.log('currency', currency);

    const params = {
      selectedCountry,
      selectedState,
      selectedCity,
      purpose,
      propertyType,
      subPropertyType,
      selectedMin,
      selectedMax,
      currency,
      min_total_area,
      max_total_area,
      selectedAreaOption,
      min_Bathrooms,
      max_Bathrooms,
      min_Bedrooms,
      max_Bedrooms,
      skip: 0,
      limit: 500
    };
    console.log('Params passed to buildQuery:', params);
    const url = buildQuery(params);
    console.log('search url', url);
    console.log('selectedMin:', selectedMin);
    console.log('selectedMax:', selectedMax);
    console.log('selectedCurrency:', selectedCurrency);
    console.log('min_total_area:', min_total_area);
    console.log('max_total_area:', max_total_area);
    console.log('min_Bathrooms:', min_Bathrooms);
    console.log('max_Bathrooms:', max_Bathrooms);
    console.log('min_Bedrooms:', min_Bedrooms);
    console.log('max_Bedrooms:', max_Bedrooms);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        if (response.status === 204) {
          throw new Error('No data found');
        }
        return response.json();
      })
      .then((data) => {
        navigate(`/SearchProperty?${url.split('?')[1]}`, { state: { searchResults: data.documents } });
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };

  const openFilter = () => {
    setIsFilterVisible(true);
  };

  const closeFilter = () => {
    setIsFilterVisible(false);
  };

  const handleAdvanceFilterClick = () => {
    isFilterVisible ? closeFilter() : openFilter();
  };

  const handleStateAndCityClick = () => {
    closeFilter();
  };

  const handleAllFilterClick = () => {
    closeFilter();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    console.log('Alert closed');
    setAlertOpen(false);
  };
  const isAdvanceButtonDisabled = !selectedState || !selectedCity || !propertyType || !subPropertyType.length;

  return (
    <div className=" relative    ">
      <div className=" flex justify-between items-center rounded-tl-md  bg-white rounded-tr-md w-[1000px] ">
        <div className="flex items-center  " onClick={handleAllFilterClick}>
          <StateAndCity />
          <Allfilter />
        </div>

        <button
          className="px-4 font-fifth py-2 h-10 rounded-[5px] flex justify-center items-center w-auto sm:w-auto md:w-36 transform transition-all duration-300 hover:scale-105 hover:bg-opacity-90 active:scale-95 active:opacity-75 text-[18px] ml-2"
          style={headerStyle}
          onClick={handleSearch}
          disabled={isButtonDisabled}
        >
      
          {t('Search')}
        </button>

        <button
          className="px-4 py-2 w-auto  mr-0 sm:mr-0 md:mr-10 h-10 rounded-[5px] text-xl md:text-[18px]  justify-center  items-center cursor-pointer flex font-fifth transform transition-all duration-300 hover:scale-105 hover:bg-opacity-90 active:scale-95 active:opacity-75 ml-1"
          style={headerStyle}
          onClick={handleAdvanceFilterClick}
          disabled={isAdvanceButtonDisabled}
        >
        
          {t('advance')}
          <span>
            <MoreVertIcon style={{ fontSize: '20px' }} />
          </span>
        </button>
      </div>

      {isFilterVisible && (
        <div className="absolute  rounded-md shadow-md ">
          <AdvanceFilter />
        </div>
      )}
    </div>
  );
};

export default Search;

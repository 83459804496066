import React, { useState, useEffect, useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
const PriceRange = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [min, setMin] = useState(false);
  const [max, setMax] = useState(false);
  
  const { t, i18n } = useTranslation();
  const [selectedCurrency, setSelectedCurrency] = useState('Dollar');
  const priceOptions = Array.from({ length: 20 }, (_, i) => (i + 1) * 5000);

  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const dropdownRef = useRef(null);
  const minDropdownRef = useRef(null);
  const maxDropdownRef = useRef(null);

  const filteredMaxOptions = priceOptions.filter((price) => (minPrice ? price > parseInt(minPrice, 10) : true));

  const handleMinInputChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, ''); // Allow only digits
    if (value.length > 0 && value.startsWith('0')) {
      value = value.slice(1); // Remove leading zero
    }
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    setMinPrice(value);
    if (parseInt(value, 10) >= parseInt(maxPrice, 10)) {
      setMinPrice('');
    }
  };

  const handleMaxInputChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters
    if (value.length > 0 && value.startsWith('0')) {
      value = value.slice(1); // Remove leading zero
    }

    // Limit the value to 10 digits
    if (value.length > 10) {
      value = value.slice(0, 10); // Keep only the first 10 digits
    }
  
    setMaxPrice(value);
    if (parseInt(minPrice, 10) >= parseInt(value, 10)) {
      setMinPrice('');
    }
  };

  const currencies = [
    { name: 'Dollar', symbol: '$' },
    { name: 'Rupees', symbol: '₹' },
    { name: 'Qatari Riyal', symbol: '﷼' },
    { name: 'Irani Riyal', symbol: '﷼' }
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setMax(false);
    setMin(false);
  };

  const selectCurrency = (currency) => {
    setSelectedCurrency(currency.name);
    setIsOpen(false);
    setMaxPrice('');
    setMinPrice('');
  };

  const handleDropdownToggle = () => {
    setMin(!min);
    setIsOpen(false);
    setMax(false);
  };

  const handleDropdownToggleMax = () => {
    setMax(!max);
    setMin(false);
  setIsOpen(false);
  };

const truncateValue = (value) => {
  if (typeof value === 'number') {
    const stringValue = value.toString();
    return stringValue.length > 3 ? stringValue.slice(0, 3) + '...' : stringValue;
  } else if (typeof value === 'string') {
    return value.length > 3 ? value.slice(0, 3) + '...' : value;
  } else {
    return value;
  }
};

  const handleMinValueSelect = (price) => {
    setMinPrice(price);
    if (parseInt(price, 10) >= parseInt(maxPrice, 10)) {
      setMaxPrice(''); // Clear maxPrice if it's less than or equal to minPrice
    }
    setMin(false);
  };

  const handleMaxValueSelect = (price) => {
    setMaxPrice(price);
    if (parseInt(price, 10) >= parseInt(minPrice, 10)) {
      setMaxPrice(price);
      setMax(false);
    }
  };

  const handleMinInputBlur = () => {
    setMin(false); // Close dropdown when input is blurred
  };

  const handleMaxInputBlur = () => {
    setMax(false); // Close dropdown when input is blurred
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        minDropdownRef.current && !minDropdownRef.current.contains(event.target) &&
        maxDropdownRef.current && !maxDropdownRef.current.contains(event.target)
      ) {
        if (parseInt(minPrice, 10) > parseInt(maxPrice, 10)) {
          setMaxPrice('');
        }
        setIsOpen(false);
        setMin(false);
        setMax(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [minPrice, maxPrice]);

  return (
    <div>
      <div className="flex justify-between border-gray-300 border-2 rounded-md h-10 w-[300px] px-1">
        {/* Currency Dropdown */}
        <div className="relative w-[30%] flex justify-between items-center" ref={dropdownRef}>
          <div
            onClick={toggleDropdown}
            className="flex items-center justify-between  text-sm font-sixth text-[#2E4374]  font-[600] w-full whitespace-nowrap overflow-hidden mt-1"
          >
            <span className="text-[14px] ml-1 font-sixth text-[#2E4374] w-full font-[600] text-nowrap">
              {currencies.find((cur) => cur.name === selectedCurrency)?.symbol} {truncateValue(selectedCurrency)}
            </span>
            <span className="">
              <KeyboardArrowDownIcon
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </span>
          </div>

          {isOpen && (
            <div className="absolute top-full left-0 mt-0 p-2 w-36 bg-white shadow-lg rounded-md text-sm font-sixth overflow-y-auto overflow-x-hidden max-h-48">
              {currencies.map((currency) => (
                <div
                  key={currency.name}
                  onClick={() => selectCurrency(currency)}
                  className="px-2 py-2 text-[14px] text-gray-700 flex justify-start gap-1 hover:bg-gray-100 w-full hover:text-gray-900"
                >
                  <span>{currency.symbol}</span>
                  <span>{currency.name}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Min Price Input */}
        <div className="relative w-[35%] flex justify-between items-center" ref={minDropdownRef}>
          <button onClick={handleDropdownToggle} className="flex items-center text-sm px-4 font-[600] w-full whitespace-nowrap overflow-hidden mt-1">
            <span className='w-[30%] text-left relative left-[-2px]  text-[14px] font-[600] font-sixth'>  {t('Min')}</span>
            <span className="w-[60%] truncate text-[14px] ml-1  font-[600] font-sixth">{truncateValue(minPrice)}</span>
            <span className="w-[10%] text-right">
              <KeyboardArrowDownIcon
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: min ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </span>
          </button>

          {min && (
            <div className="absolute top-full right-0 mt-0 p-2 w-36 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48">
              <input
                type="text"
                className="border rounded-lg px-2 text-[14px] py-2 w-full truncate"
                placeholder="Min Price"
                value={minPrice}
                onChange={handleMinInputChange}
                onBlur={handleMinInputBlur} // Close on blur
              />
              <ul>
                {priceOptions.map((price) => (
                  <li key={price} className="px-1 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleMinValueSelect(price)}>
                    {selectedCurrency === 'Dollar' ? `$${price.toLocaleString()}` : `₹${price.toLocaleString()}`}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {/* Max Price Input */}
        <div className="relative w-[35%] flex justify-between items-center" ref={maxDropdownRef}>
          <button onClick={handleDropdownToggleMax} className="flex items-center text-sm px-4  font-[600] w-full whitespace-nowrap overflow-hidden mt-1">
            <span className='w-[30%] text-left text-[14px] font-[600] relative left-[-2px] font-sixth mr-2'>  {t('Max')}</span>
            <span className="w-[60%] truncate text-[14px] ml-2 font-[600] font-sixth">{truncateValue(maxPrice)}</span>
            <span className="w-[10%] text-right">
              <KeyboardArrowDownIcon
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: max ? 'rotate(180deg)' : 'rotate(0deg)',
                }}
              />
            </span>
          </button>
          {max && (
            <div className="absolute top-full right-0 mt-0 p-2 w-36 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48">
              <input
                type="text"
                className="border rounded-lg px-2 text-[14px] py-2 w-full truncate"
                placeholder="Max Price"
                value={maxPrice}
                onChange={handleMaxInputChange}
                onBlur={handleMaxInputBlur} // Close on blur
              />
              <ul>
                {filteredMaxOptions.map((price) => (
                  <li key={price} className="px-1 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleMaxValueSelect(price)}>
                    {selectedCurrency === 'Dollar' ? `$${price.toLocaleString()}` : `₹${price.toLocaleString()}`}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceRange;

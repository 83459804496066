import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropertyDetail from './PropertyDetail';
import Amenities from './Amenities';
import Aboutproject from './Aboutproject';
import Masterplan from './Masterplan';
import Reviewproject from './Reviewproject';
import PropertyCard from './PropertyCard';
import Map from '../AboutPage/Map';
import Photes from './Photos';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { fetchPropertyDetails } from '../../redux/SingleSearchPropertyReducer';
import './Singlesearch.css';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import debounce from 'lodash.debounce';

const Loader = () => (
  <div
    className="fixed inset-0 flex items-center justify-center bg-white opacity-120 z-50"
    aria-live="assertive"
    aria-label="Loading..."
  >
    <div className="w-16 h-16 border-4 border-t-4 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
  </div>
);

const SingleSearchProperty = () => {
  const { property_id } = useParams();
  const dispatch = useDispatch();
  const { propertyDetails, imageDetails, loading, error } = useSelector((state) => state.propertyDetails);
  const [selectedOption, setSelectedOption] = useState('PropertyDetails');
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [isSticky, setIsSticky] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [scrollPosition, setScrollPosition] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [selectedlabel, setSelectedlabel] = useState(null);
  const divRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);

  const refs = {
    propertyDetailsRef: useRef(null),
    amenitiesRef: useRef(null),
    photosRef: useRef(null),
    masterPlanRef: useRef(null),
    aboutProjectRef: useRef(null),
    reviewRef: useRef(null)
  };

  const sections = [
    {
      id: 'PropertyDetails',
      label: 'Property Details',
      ref: refs.propertyDetailsRef,
      icon: 'https://d34yb5orp91dp6.cloudfront.net/propertydetails.svg'
    },
    {
      id: 'Amenities',
      label: 'Amenities',
      ref: refs.amenitiesRef,
      icon: 'https://d34yb5orp91dp6.cloudfront.net/Amenities.svg'
    },
    { id: 'Photos', label: 'Photos', ref: refs.photosRef, icon: 'https://d34yb5orp91dp6.cloudfront.net/photo.svg' },
    {
      id: 'MasterPlan',
      label: 'Master Plan',
      ref: refs.masterPlanRef,
      icon: 'https://d34yb5orp91dp6.cloudfront.net/Master.svg'
    },
    {
      id: 'AboutProject',
      label: 'About Project',
      ref: refs.aboutProjectRef,
      icon: 'https://d34yb5orp91dp6.cloudfront.net/About.svg'
    },
    { id: 'Review', label: 'Review', ref: refs.reviewRef, icon: 'https://d34yb5orp91dp6.cloudfront.net/Review.svg' }
  ];

  useEffect(() => {
    dispatch(fetchPropertyDetails(property_id));
  }, [dispatch, property_id]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollTop = window.scrollY;
      const sectionOffsets = sections.map((section) => section.ref.current.getBoundingClientRect().top + scrollTop);

      for (let i = 0; i < sectionOffsets.length; i++) {
        if (
          scrollTop >= sectionOffsets[i] - 120 &&
          scrollTop < sectionOffsets[i] + (sectionOffsets[i + 1] || Infinity) - sectionOffsets[i] - 120
        ) {
          setSelectedOption(sections[i].id);
          break;
        }
      }
    }, 50);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      // You can adjust this scroll position value to decide when the div should become fixed
      if (scrollTop > 70) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const handleButtonClick = (option, ref) => {
  //   setSelectedOption(option);
  //   if (ref && ref.current) {
  //     window.scrollTo({ top: ref.current.offsetTop - 100, behavior: 'smooth' });
  //   }
  // };
  const handleButtonClick = (id, ref) => {
    setSelectedlabel(id);
    if (ref && ref.current) {
      window.scrollTo({ top: ref.current.offsetTop - 120, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleArrowClick = (direction) => {
    const newIndex = (currentIndex + direction + sections.length) % sections.length;
    setCurrentIndex(newIndex);
    handleButtonClick(sections[newIndex].id, sections[newIndex].ref); // Scroll to the new section
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) {
        setItemsPerPage(2);
      } else if (window.innerWidth < 768) {
        setItemsPerPage(3);
      } else if (window.innerWidth < 1024) {
        setItemsPerPage(4);
      } else {
        setItemsPerPage(6);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call on initial load

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNextSlide = () => {
    if (startIndex < sections.length - itemsPerPage) {
      setStartIndex(startIndex + 1);
    }
  };

  const handlePreviousSlide = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (error || !propertyDetails) {
    return <p>No property details found.</p>;
  }

  const bg = {
    background: 'linear-gradient(252deg, #071C35 -120.79%, #5B8BC5 169.82%)'
  };

  const superArea = propertyDetails.area.measurement.find((measurement) => measurement.super_area);

  const imageBaseUrl = 'https://d34yb5orp91dp6.cloudfront.net/';
  const defaultImageUrl = imageDetails?.default?.[0];
  const fullImageUrl = defaultImageUrl ? `${imageBaseUrl}${defaultImageUrl}` : null;

  return (
    <div className="p-4 mx-auto max-w-7xl">
      <div className=" mt-10 relative ">
        <div className="flex items-center justify-center ">
          {fullImageUrl ? (
            <img
              src={fullImageUrl}
              alt="Property"
              className="bg-cover bg-center bg-no-repeat w-full h-[400px] flex mx-auto"
            />
          ) : (
            <div className="">
              <img
                src="https://d34yb5orp91dp6.cloudfront.net/no_images.png"
                alt="No Imageavailable"
                className="w-full h-[360px] object-contain"
              />
            </div>
          )}
        </div>

        <div className="absolute top-40 xs:left-6 sm:left-6 left-8 xs:w-[300px] sm:w-[300px] md:w-[400px] hidden md:block h-auto bg-blue-100 p-5 rounded-tl-[20px] rounded-br-[20px]">
          <div className="">
            <h1 className=" text-[16px] sm:text-[16px] md:text-[16px] lg:text-[20px] xl:text-[24px] leading-[20px] font-fifth text-[#1E2858] uppercase font-[500]   ">
              {propertyDetails.title}
            </h1>
            <p className="text-[#2E4374] font-sixth font-[400] leading-[20px] mt-2 text-[14px] sm:text-[14px] md:text-[16px] lg:text-[16px] xl:text-[16px]  ">
              {propertyDetails.description}
            </p>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-5">
            <div className="flex gap-2">
              <img src="https://d34yb5orp91dp6.cloudfront.net/Style.svg" alt="tag" className=" w-4 h-4 mt-1" />
              <span className="font-sixth font-[400] text-[#2e4374  ] text-[14px] mt-1">
                {propertyDetails.sub_property_type}
              </span>
            </div>
            <div className="flex gap-2 ">
              <img src="https://d34yb5orp91dp6.cloudfront.net/MGBED.svg" alt="bed" className=" w-5 h-5 mt-1 ml-2" />
              <span className="font-sixth font-[400] text-[#2e4374] text-[14px] mt-1">
                Bedroom
                <span className="font-sixth font-[400] text-[#818EAE] text-[14px]">{propertyDetails.bedroom}</span>
              </span>
            </div>
            <div className="flex gap-2 ">
              <img src="https://d34yb5orp91dp6.cloudfront.net/Vector.svg" alt="tag" className=" w-4 h-4 mt-2" />
              <span className="font-sixth font-[400] text-[#2e4374] text-[14px] mt-1">
                Super Area
                <span> {superArea ? `${superArea.super_area.value} ${superArea.super_area.unit}` : 'N/A'} </span>
              </span>
            </div>
          </div>

          <div className="flex mt-4 lg:items-start">
            <button className="px-3 py-0.5  border-2 font-sixth font-[400] bg-[#2e4374] hover:border-[#2e4374] hover:text-[#2e4374] rounded-md shadow-sm text-white hover:bg-blue-100">
              Enquiry Now
            </button>
          </div>
        </div>
      </div>

      <div
        ref={divRef}
        className={`flex justify-evenly bg-blue-200 w-full z-50 ${isFixed ? 'fixed top-[70px] left-0   ' : 'mt-3 '}`}
      >
        {/* <div
        className={`flex justify-evenly bg-[#E8F1FA] bg-[#c9e1fc] bg-[#b4d5f6] mx-auto mt-4 w-full max-w-7xl ${
          isSticky ? 'sticky top-28 mt-[70px] w-full ' : 'top-28 mt-[15px]'
        }`}
      > */}
        {/* Previous Button */}
        <div className="lg:hidden flex">
          {currentIndex > 0 && ( // Only render if not at the first section
            <button
              onClick={() => {
                handleArrowClick(-1); // Move to the previous slide
                handlePreviousSlide(); // Call additional functionality for previous slide
              }}
              className="text-white p-2 rounded-full"
            >
              <KeyboardArrowLeftIcon />
            </button>
          )}
        </div>
        {/* Carousel Items */}
        <div className="flex 2xl:gap-24 xl:gap-24 lg:gap-16 md:gap-14 sm:gap-16 xs:gap-10 justify-evenly md:pl-2 md:pr-2 ">
          {sections.slice(startIndex, startIndex + itemsPerPage).map((section, index) => (
            <div
              key={section.id}
              onClick={() => handleButtonClick(section.id, section.ref)}
              className={`flex gap-2 items-center p-2 mt-2 cursor-pointer text-[#4391C2] 
                ${selectedlabel === section.id ? 'bg-white rounded-t-2xl' : ''}`}
            >
              <img src={section.icon} alt={section.label} className="w-4 h-4" />
              <span className="capitalize font-fifth font-[400] text-[14px] md:text-[16px]">{section.label}</span>
            </div>
          ))}
        </div>
        {/* Next Button */}
        <div className="lg:hidden flex">
          {currentIndex < sections.length - 1 && ( // Only render if not at the last section
            <button
              onClick={() => {
                handleArrowClick(1); // Move to the next slide
                handleNextSlide(); // Call additional functionality for next slide
              }}
              className="text-white p-2 rounded-full"
            >
              <KeyboardArrowRightIcon />
            </button>
          )}
        </div>
      </div>

      <div className=" md:hidden ">
        <div className="flex justify- mt-5">
          <div>
            <h1 className="text-[#1E2858] font-fifth font-[400] text-sm sm:text-sm md:text-xl">
              {propertyDetails.title}
            </h1>
            <p className="text-[#2E4374] font-fifth font-[400] text-sm w-[1px]">{propertyDetails.description}</p>
          </div>
          <div className="flex flex-col items-end w-full">
            <h1 className="text-[#1E2858] font-fifth font-[400] text-[14px]">
              <span>
                <FileDownloadIcon style={{ color: '#2E4374', fontSize: '15px' }} />
              </span>
              Download brochure
            </h1>
            <button className="px-3 mt-4 py-0 text-[#fff] border-2 text-sm font-sixth font-[400] border-[#2e4374] rounded-[4px] bg-[#1E2858]">
              Enquiry Now
            </button>
          </div>
        </div>
      </div>

      <div className="mt-10 md:mt-4 mx-auto max-w-7xl">
        <div ref={refs.propertyDetailsRef}>
          <PropertyDetail property={propertyDetails} />
        </div>
        <div className="">
          <Map location={propertyDetails.location} />
        </div>
        <div ref={refs.amenitiesRef}>
          <Amenities amenities={propertyDetails.amenities} />
          <PropertyCard propertyList={propertyDetails.similarProperties} />
        </div>
        <div ref={refs.photosRef}>
          <Photes photos={propertyDetails.photos} />
        </div>
        <div ref={refs.masterPlanRef}>
          <Masterplan masterplan={propertyDetails.masterplan} />
        </div>
        <div ref={refs.aboutProjectRef}>
          <Aboutproject aboutProject={propertyDetails.aboutProject} />
        </div>
        <div ref={refs.reviewRef}>
          <Reviewproject review={propertyDetails.review} />
        </div>
      </div>
    </div>
  );
};

export default SingleSearchProperty;

import React, { useState, useEffect } from 'react';
import BuyPage from './BuyPropertyPage';
import SellPage from './SellPropertyPage';
import RentPage from './RentPage';

const MorePropertyDetails = () => {
  const [selectedOption, setSelectedOption] = useState('buy');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleButtonClick = (option) => {
    setSelectedOption(option);
  };

  const renderComponent = () => {
    switch (selectedOption) {
      case 'buy':
        return (
          <div className="">
            <BuyPage />
          </div>
        );
      case 'sell':
        return (
          <div className="">
            <SellPage />
          </div>
        )
      case 'rent':
        return <div className=''>
          <RentPage />
        </div>;
      default:
        return null;
    }
  };

  const bg = {
    background: 'linear-gradient(252deg, #071C35 -255.72%, #5B8BC5 169.9%)'
  };

  return (
    <div className="flex flex-col items-center md:items-start mt-10 mx-auto max-w-7xl">
<h1 className="relative text-base sm:text-base md:text-2xl font-fifth mb-4 uppercase font-semibold">
  <span className='underlineresponsive'>Best Property Opt</span>ions in Istanbul
  {/* <span
    className="absolute md:w-52 w-52 h-1 md:mt-8 -mt-2 bg-[#4BA3DB] leading-tight"
    style={{ left: 0, marginBottom: '2px' }}
  ></span> */}
</h1>

      <div className="flex flex-wrap justify-center md:justify-start  mt-2">
        <button
          className={`py-2 px-4 rounded-full focus:outline-none ${
            selectedOption === 'buy'
              ? 'bg-green-500 text-white'
              : 'bg-gray-200 text-[#1E2858] border-2  shadow-md border-[#bae6fd]  hover:bg-gray-300'
          }`}
          style={selectedOption === 'buy' ? bg : {}}
          onClick={() => handleButtonClick('buy')}
        >
          Buy
        </button>
        <button
          className={`py-2 px-4 rounded-full focus:outline-none ${
            selectedOption === 'sell'
              ? 'bg-green-500 text-white'
              : 'bg-white border-2  shadow-md border-[#bae6fd]  text-[#1E2858] hover:bg-gray-300'
          }`}
          style={selectedOption === 'sell' ? bg : {}}
          onClick={() => handleButtonClick('sell')}
        >
          Sell
        </button>
        <button
          className={`py-2 px-4 rounded-full focus:outline-none ${
            selectedOption === 'rent'
              ? 'bg-green-500 text-white'
              : ' text-[#1E2858] bg-white  border-2  shadow-md border-[#bae6fd] hover:bg-gray-300'
          }`}
          style={selectedOption === 'rent' ? bg : {}}
          onClick={() => handleButtonClick('rent')}
        >
          Rent
        </button>
      </div>
      <div className="">{renderComponent()}</div>
    </div>
  );
};

export default MorePropertyDetails;

import React, { useState, useEffect } from 'react';
import { blogDta } from '../componenet/propertyDummyData';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMediaQuery from '@mui/material/useMediaQuery';

const Card = ({ image, description, date, buttonLabel }) => {
  return (
    <div className="rounded overflow-hidden flex flex-col bg-white shadow-lg m-2 h-auto border-2 border-[#E5EFFF] relative w-full sm:w-full md:w-[100%]">
      <div className="flex justify-center">
        <img src={image} alt={description} className="w-3/4 h-48 object-cover mt-4 rounded-md" />
      </div>
      <div className="p-2 text-center w-full h-28">
        <h2 className=" font-fifth font-[700] text-start leading-[20px] ml-8 mt-4 ">
          {description}
        </h2>
        <p className="text-[#1E2858] mb-2 font-sixth text-xl text-start ml-8">{date}</p>
      </div>
      <div className="flex justify-center mb-2 h-16">
        <button
          className="font-thirdCustom text-[#1E2858] h-10 w-1/2 hover:bg-[#2E4374] font-semibold text-sm md:text-sm lg:text-base hover:text-white hover:border-[#1E2858] hover:border-2 mt-4 transition-transform transform hover:-translate-y-1 transition-all duration-300"
          style={{ borderRadius: '4px', border: '2px solid rgba(46, 67, 116, 0.70)' }}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};

const BlogPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isTabletScreen = useMediaQuery('(max-width:1050px)');
  const isSmallScreen = useMediaQuery('(max-width:750px)');

  const numCardsToShow =
    windowWidth >= 2000 ? 4 : windowWidth >= 1600 ? 3 : windowWidth >= 1200 ? 3 : windowWidth >= 768 ? 2 : 1;

  const totalCards = blogDta.length;

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalCards - numCardsToShow : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === totalCards - numCardsToShow ? 0 : prevIndex + 1));
  };

  const shouldShowPrevButton = currentIndex > 0;
  const shouldShowNextButton = currentIndex + numCardsToShow < totalCards;

  return (
    <div className="container mx-auto mt-10 sm:mt-10 md:mt-20 mx-auto max-w-7xl p-2">
      <h2
        className="relative inline-block mb-4 pb-2 text-sm sm:text-sm md:text-2xl lg:text-3xl font-bold text-[#2E4374]  font-fifth  xs:ml-12  md:ml-10 uppercase text-center md:text-left"

      >
       <span className='underlineresponsive'> our latest </span> blogs
        
      </h2>

      <div className="flex relative justify-center p-4">
        <div className="flex gap-4 overflow-hidden w-full">
          {blogDta.slice(currentIndex, currentIndex + numCardsToShow).map((card, index) => (
            <div
              key={index}
              className="w-full transform transition-transform duration-300 ease-in-out px-2 m-4"
              style={{ width: isSmallScreen ? '100%' : `${100 / numCardsToShow}%` }}
            >
              <Card buttonLabel={card.buttonLabel} description={card.description} image={card.image} date={card.date} />
            </div>
          ))}
        </div>
        {shouldShowPrevButton && (
          <button
            className="absolute top-1/2 transform -translate-y-1/2 left-4 bg-white p-2 shadow-lg rounded-lg opacity-60"
            style={{ left: isTabletScreen ? '10px' : '' }}
            onClick={goToPrevSlide}
          >
            <ArrowBackIosIcon />
          </button>
        )}
        {shouldShowNextButton && (
          <button
            className="absolute top-1/2 transform -translate-y-1/2 right-0 shadow-lg rounded-lg bg-white p-2 opacity-60"
            onClick={goToNextSlide}
            style={{ right: isTabletScreen ? '0px' : '' }}
          >
            <ArrowForwardIosIcon />
          </button>
        )}
      </div>

      {/* Mobile view */}
      <div className="lg:hidden relative">
        <div className="flex gap-4 overflow-hidden">
          {blogDta.slice(currentIndex, currentIndex + numCardsToShow).map((card, index) => (
            <div key={index} className="w-full px-2">
              <Card buttonLabel={card.buttonLabel} description={card.description} image={card.image} date={card.date} />
            </div>
          ))}
        </div>

        {/* Navigation buttons */}
        {shouldShowPrevButton && (
          <button
            className="absolute top-1/2 transform -translate-y-1/2 bg-white p-2 left-2"
            onClick={goToPrevSlide}
          >
            <ArrowBackIosIcon />
          </button>
        )}
        {shouldShowNextButton && (
          <button
            className="absolute top-1/2 transform -translate-y-1/2 bg-white p-2 right-2"
            onClick={goToNextSlide}
          >
            <ArrowForwardIosIcon />
          </button>
        )}
      </div>

      
    </div>
  );
};

export default BlogPage;

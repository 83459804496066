import React, { useState, useEffect } from 'react';

import MyAgents from './MyAgents';

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSelector } from 'react-redux';
import {
  ResidentialHouse,
  ResidetialPLOT,
  CommercialShopCard,
  CommercialItPark,
  Villa,
  Builder,
  Agriculture,
  FormHouse
} from './AllPropertycard';
import { CommercialLand } from './AllPropertycard';
import { InderstrialLandCard } from './AllPropertycard';
import { FlatApartmentCard } from './AllPropertycard';
import DynamicFilterMainPage from './DynamicFilter/DynamicFilterMainPage';

export const AllSearchProperty = () => {
  const [propertyData, setPropertyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 5;
  const location = useLocation();
  const { searchResults: initialResults } = location.state || {};
  const [error, setError] = useState(null); // Add state for error handling
  const navigate = useNavigate();

  const selectedCity = useSelector((state) => state.location.selectedCity);

  const purpose = useSelector((state) => state.filter.selectedButton);
  const propertyType = useSelector((state) => state.properties.propertyType);
  const subPropertyType = useSelector((state) => state.properties.subPropertyType);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loading) {
      setPropertyData(propertyData);
    }
  }, [propertyData, currentPage, cardsPerPage]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        let url;

        if (initialResults) {
          setPropertyData(initialResults);
        } else {
          const query = new URLSearchParams(location.search);

          // Helper function to add parameters if they are valid
          const addParam = (params, key, value, type) => {
            if (value !== null && value !== '' && !isNaN(value)) {
              params.append(key, type === 'float' ? parseFloat(value) : parseInt(value, 10));
            }
          };

          const params = new URLSearchParams();
          params.append('country', query.get('country'));
          params.append('state', query.get('state'));
          params.append('sub_city', query.get('sub_city'));
          params.append('purpose', query.get('purpose'));
          params.append('property_type', query.get('property_type'));
          params.append('sub_property_type', query.get('sub_property_type'));
          params.append('min_price', query.get('min_price'));
          params.append('max_price', query.get('max_price'));

          // Add currency type if valid
          const currencyType = query.get('currency_type');
          const validCurrencies = ['usd', 'EUR', 'INR', 'GBP', 'JPY']; // Replace with valid currency types from API documentation

          if (currencyType && validCurrencies.includes(currencyType.toUpperCase())) {
            params.append('currency_type', currencyType.toUpperCase());
          } else {
            console.error('Invalid currency type:', currencyType);
          }

          params.append('area_unit', query.get('area_unit'));

          // Add optional parameters conditionally
          addParam(params, 'min_bathrooms', query.get('min_bathrooms'), 'integer');
          addParam(params, 'max_bathrooms', query.get('max_bathrooms'), 'integer');
          addParam(params, 'min_bedrooms', query.get('min_bedrooms'), 'integer');
          addParam(params, 'max_bedrooms', query.get('max_bedrooms'), 'integer');
          addParam(params, 'min_total_area', query.get('min_total_area'), 'float');
          addParam(params, 'max_total_area', query.get('max_total_area'), 'float');

          params.append('skip', (currentPage - 1) * cardsPerPage);
          params.append('limit', cardsPerPage);

          url = `https://square-bric-backend-api.squarebric.com/property-search/property-search?${params.toString()}`;

          const response = await fetch(url);

          if (!response.ok) {
            const errorText = await response.text();
            console.error(`Fetch error: ${response.status} - ${response.statusText}`);
            console.error('Response body:', errorText);
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          setPropertyData(data.documents || []);
        }
      } catch (error) {
        setError('There was a problem with the fetch operation. Please try again later.');
        console.error('There was a problem with the fetch operation:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [initialResults, location.search, currentPage, cardsPerPage]); // Added cardsPerPage to dependencies

  const filteredProperties = propertyData.filter(
    (property) => subPropertyType.length === 0 || subPropertyType.includes(property.sub_property_type)
  );

  const totalItems = filteredProperties.length;
  const pageCount = Math.ceil(totalItems / cardsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, pageCount));
  };

  const renderPaginationButtons = () => {
    const maxVisiblePages = 5;
    const halfVisiblePages = Math.floor(maxVisiblePages / 2);
    let startPage = Math.max(currentPage - halfVisiblePages, 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, pageCount);

    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    const buttons = [];

    if (startPage > 1) {
      buttons.push(
        <li key="first" className="mx-1">
          <button onClick={() => handlePageChange(1)} className="px-3 py-1 rounded-full bg-gray-300 text-[#071C35]">
            1
          </button>
        </li>
      );
      if (startPage > 2) {
        buttons.push(
          <li key="ellipsis-1" className="mx-1">
            <button className="px-3 py-1 rounded-full bg-gray-300 text-[#071C35]">...</button>
          </li>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className="mx-1">
          <button
            onClick={() => handlePageChange(i)}
            className={`px-3 py-1 rounded-full ${
              currentPage === i ? 'bg-[#2E4374] text-white' : 'bg-gray-300 text-[#071C35]'
            }`}
          >
            {i}
          </button>
        </li>
      );
    }

    if (endPage < pageCount) {
      if (endPage < pageCount - 1) {
        buttons.push(
          <li key="ellipsis-2" className="mx-1">
            <button className="px-3 py-1 bg-gray-300 text-[#071C35] rounded-full">...</button>
          </li>
        );
      }
      buttons.push(
        <li key={pageCount} className="mx-1">
          <button
            onClick={() => handlePageChange(pageCount)}
            className={`px-3 py-1 rounded-full ${
              currentPage === pageCount ? 'bg-[#2E4374] text-white' : 'bg-gray-300 text-[#071C35]'
            }`}
          >
            {pageCount}
          </button>
        </li>
      );
    }

    return <>{buttons}</>;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const paginatedData = filteredProperties.slice(startIndex, endIndex);
  const baseImageUrl = 'https://d34yb5orp91dp6.cloudfront.net/';

  const renderPropertyCard = (property) => {
    const commonProps = {
      key: property.property_id,
      property_id: property.property_id,
      title: property.title || 'N/A',
      property_address: property.property_address || 'N/A',
      cost: property.currency_usd ? `$${property.currency_usd}` : 'N/A',
      imageUrl:
        property.ImageDetails && property.ImageDetails.length > 0
          ? `${baseImageUrl}${property.ImageDetails[0]}`
          : 'https://d34yb5orp91dp6.cloudfront.net/no_images.png',
      tag:
        property.property_type && property.sub_property_type
          ? `${property.property_type} - ${property.sub_property_type.replace(/([A-Z])/g, ' $1').trim()}`
          : 'N/A'
    };

    switch (property.sub_property_type) {
      case 'FlatApartment':
        return (
          <FlatApartmentCard
            {...commonProps}
            {...{
              SuperArea: property.area_sqft ? `${property.area_sqft} sqft` : 'N/A',
              propertyonfloor: property.no_of_floor ? `Floor ${property.no_of_floor}` : 'N/A',
              Possessionstatus: property.possession_status || 'N/A',
              Furnishedstatus: property.furnished_status || 'N/A',
              bathroom: property.bathroom || 'N/A',
              bedroom: property.bedroom || 'N/A',
              facing: property.facing || 'N/A',
              status: property.status || 'N/A',
              enquiredBy: property.enquiredBy || 0,
              sharedBy: property.sharedBy || 0
            }}
          />
        );
      case 'ResidentialHouse':
        return (
          <ResidentialHouse
            {...commonProps}
            {...{
              SuperArea: property.area_sqft ? `${property.area_sqft} sqft` : 'N/A',
              propertyonfloor: property.no_of_floor ? `Floor ${property.no_of_floor}` : 'N/A',
              Possessionstatus: property.possession_status || 'N/A',
              Furnishedstatus: property.furnished_status || 'N/A',
              bathroom: property.bathroom || 'N/A',
              bedroom: property.bedroom || 'N/A',
              facing: property.facing || 'N/A',
              status: property.status || 'N/A',
              enquiredBy: property.enquiredBy || '',
              sharedBy: property.sharedBy || 0
            }}
          />
        );
      case 'CommercialShop':
        return (
          <CommercialShopCard
            {...commonProps}
            {...{
              status: property.status || 'N/A',
              SuperArea: property.area_sqft ? `${property.area_sqft} sqft` : 'N/A',
              Propertyonfloor: property.no_of_floor ? `Floor ${property.no_of_floor}` : 'N/A',
              Possessionstatus: property.possession_status || 'N/A',
              Furnishedstatus: property.furnished_status || 'N/A',
              facing: property.facing || 'N/A',
              enquiredBy: property.enquiredBy || 0,
              sharedBy: property.sharedBy || 0
            }}
          />
        );

      case 'CommercialLand':
        return (
          <CommercialLand
            {...commonProps}
            {...{
              SuperArea: property.area_sqft ? `${property.area_sqft} sqft` : 'N/A',
            
              LandZone: property.land_zone || 'N/A',
              Boundarywall: property.boundary_wall || 'N/A',
              Noofopenside: property.number_open_sides || 'N/A',
              facing: property.facing || 'N/A',
              status: property.status || 'N/A',
              enquiredBy: property.enquiredBy || '',
              sharedBy: property.sharedBy || 0,
              cost: property.cost ? `₹${property.cost}` : 'N/A'
              // imageUrl: property.image_url || '', // Assuming there's an image URL property
              // tag: property.tag || 'N/A',
            }}
          />
        );

      case 'CommercialItPark':
        return (
          <CommercialItPark
            {...commonProps}
            {...{
              Superarea: property.area_sqft ? `${property.area_sqft} sqft` : 'N/A',
              Totalnooffloor: property.total_floor ? property.total_floor : 'N/A',
              Furnishedstatus: property.furnished_status || 'N/A',
              NearbyBusiness: property.nearby_business || 'N/A',
              Noofopenside: property.number_open_sides || 'N/A',
              facing: property.facing || 'N/A',
              status: property.status || 'N/A',
              enquiredBy: property.enquiredBy || 0,
              sharedBy: property.sharedBy || 0,
              cost: property.currency_usd ? `$${property.currency_usd}` : 'N/A'
            }}
          />
        );
      case 'Villa':
        return (
          <Villa
            {...commonProps}
            {...{
              Totalarea: property.total_area ? `${property.total_area} sqft` : 'N/A',
              Superarea: property.area_sqft ? `${property.area_sqft} sqft` : 'N/A',
              facing: property.facing || 'N/A',
              bathroom: property.bathroom || 'N/A',
              bedroom: property.bedroom || 'N/A',
              Possessionstatus: property.possession_status || 'N/A',
              Furnishedstatus: property.furnished_status || 'N/A',
              Dinningroom: property.dinning_room || 'N/A',
              enquiredBy: property.enquiredBy || 0,
              sharedBy: property.sharedBy || 0,
              cost: property.cost ? `₹${property.cost}` : 'N/A'
            }}
          />
        );
      case 'BuilderFloor':
        return (
          <Builder
            {...commonProps}
            {...{
              Supperarea: property.area_sqft ? `${property.area_sqft} sqft` : 'N/A',
              facing: property.facing || 'N/A',
              Bathrooms: property.bathroom || 'N/A',
              Bedroom: property.bedroom || 'N/A',
              Possessionstatus: property.possession_status || 'N/A',
              enquiredBy: property.enquiredBy || 0,
              sharedBy: property.sharedBy || 0,
              cost: property.cost ? `₹${property.cost}` : 'N/A'
            }}
          />
        );

      case 'Agriculture':
        return (
          <Agriculture
            {...commonProps}
            {...{
              status: property.status || 'N/A',
              // Selectunit: property.select_unit || 'N/A',
              // Outputunit: property.output_unit || 'N/A',
              Totalarea: property.total_area ? `${property.total_area} sqft` : 'N/A',
              // NearbyBusiness: property.nearby_business || 'N/A',
              Noofopenside: property.number_open_sides || 'N/A',
              enquiredBy: property.enquiredBy || 0,
              sharedBy: property.sharedBy || 0,
              cost: property.cost ? `₹${property.cost}` : 'N/A'
            }}
          />
        );
      case 'Farmhouse':
        return (
          <FormHouse
            {...commonProps}
            {...{
              propertyonfloor: property.property_on_floor || 'N/A',
              Possessionstatus: property.possession_status || 'N/A',
              SuperArea: property.area_sqft ? `${property.area_sqft} sqft` : 'N/A',
              bathroom: property.bathroom || 'N/A',
              facing: property.facing || 'N/A',
              bedroom: property.bedroom || 'N/A',
              enquiredBy: property.enquiredBy || 0,
              sharedBy: property.sharedBy || 0,
              cost: property.cost ? `₹${property.cost}` : 'N/A'
            }}
          />
        );
      case 'IndustrialLand':
        return (
          <InderstrialLandCard
            {...commonProps}
            {...{
              property_id: property.id,
              title: property.title || 'N/A',
              property_address: property.address || 'N/A',
              // Selectunit: property.select_unit || 'N/A',
              // Outputunit: property.output_unit || 'N/A',
              Totalarea: property.total_area ? `${property.total_area} sqft` : 'N/A',
              // NearbyBusiness: property.nearby_business || 'N/A',
              Noofopenside: property.number_open_sides || 'N/A',
              facing: property.facing || 'N/A',
              status: property.status || 'N/A',
              enquiredBy: property.enquiredBy || '',
              sharedBy: property.sharedBy || 0,
              cost: property.cost ? `₹${property.cost}` : 'N/A',
              imageUrl: property.image_url || '', // Assuming there's an image URL property
              tag: property.tag || 'N/A'
            }}
          />
        );
      case 'ResidentialLandPlot':
        return (
          <ResidetialPLOT
            {...commonProps}
            property_id={property.id}
            title={property.title}
            property_address={property.address}
            area={property.area}
            Plotlength={property.Plotlength}
            Plotbreath={property.Plotbreath}
            facing={property.facing}
            Isthisacornerplate={property.Isthisacornerplate}
            enquiredBy={property.enquiredBy}
            sharedBy={property.sharedBy}
            cost={property.cost}
            imageUrl={property.image_url}
            tag={property.tag}
          />
        );
      default:
        return null;
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className=" overflow-x-hidden  flex justify-center w-[100%]  items-center mt-20">
      <div className="flex   max-w-7xl mx-auto   gap-4 flex-col  md:flex-col lg:flex-col xl:flex-row md:justify-center lg:justify-center lg:items-center items-center md:items-center xl:items-start  ">
        <div className="flex flex-row gap-4">
          <div className="  mt-16 ">
            <DynamicFilterMainPage />
          </div>

          <div className="w-full  mt-0 xs:mt-8 sm:mt-8 md:mt-0 mr-4 md:w-[720px] ">
            <div className="flex justify-start gap-2 md:text-sm mt-7 w-full" style={{ fontSize: '15px' }}>
              <div
                onClick={() => handleNavigate('/')}
                className={`cursor-pointer font-fifth flex font-[500] text-[#1E2858] text-[16px]   ${
                  window.location.pathname === '/' ? 'underline' : ''
                }`}
              >
                Home
                <ArrowForwardIcon sx={{ fontSize: '15px', marginTop: '3px', marginLeft: '3px' }} />
              </div>

              {subPropertyType.length > 0 && (
                <div className="font-fifth font-[500] text-[#1E2858] text-[15px] text-wrap ">
                  {propertyType} Properties for {purpose} in {selectedCity}{' '}
                  <ArrowForwardIcon sx={{ fontSize: '15px', marginTop: '0px', marginLeft: '0px' }} />
                  Total results {propertyData.length}
                </div>
              )}
            </div>

            <div className="  ">
              {paginatedData.length === 0 ? (
                <div className="flex flex-col justify-center items-center mt-[10vw]">
                  <div className="font-fifth font-[500] text-[#2E4374] text-[18px]">
                    We are Sorry We not able to find a match
                  </div>
                  <div className="font-fifth font-[500] text-[#2E4374] text-[18px]">Try Another Search</div>
                </div>
              ) : (
                paginatedData.map(renderPropertyCard)
              )}
            </div>
            {/* Pagination Buttons */}
            <div className="md:flex justify-center items-center bg-white mt-4 hidden md:block">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className={`mx-1 px-3 py-1 rounded-full ${
                  currentPage === 1 ? 'bg-gray-300 text-gray-500' : 'bg-gray-300 text-[#071C35]'
                }`}
              >
                &lt;&lt;
              </button>

              {/* Render Pagination Numbers */}
              <ul className="flex justify-center items-center">{renderPaginationButtons()}</ul>

              <button
                onClick={handleNextPage}
                disabled={currentPage === pageCount}
                className={`mx-1 px-3 py-1 rounded-full ${
                  currentPage === pageCount ? 'bg-gray-300 text-gray-500' : 'bg-gray-300 text-[#071C35]'
                }`}
              >
                &gt;&gt;
              </button>
            </div>
          </div>
        </div>

        {/* Add a responsive pagination for smaller screens */}
        <div className="md:hidden flex justify-between items-center mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`mx-1 px-3 py-1 rounded-full ${
              currentPage === 1 ? 'bg-gray-300 text-gray-500' : 'bg-gray-300 text-[#071C35]'
            }`}
          >
            &lt;&lt;
          </button>
          <ul className="flex">{renderPaginationButtons()}</ul>
          <button
            onClick={handleNextPage}
            disabled={currentPage === pageCount}
            className={`px-3 py-1 mx-1 text-lg font-bold ${
              currentPage === pageCount
                ? 'bg-[#2e4374] text-white rounded-full'
                : 'text-[#071C35] bg-gray-200 rounded-full'
            }`}
          >
            &gt;&gt;
          </button>
        </div>

        <div className=" mt-0 hidden    md:block w-full sm:w-full md:w-[800px] xl:w-auto sm:mt-0 md:mt-10  flex justify-center items-center">
          <MyAgents />
        </div>
      </div>
    </div>
  );
};

export default AllSearchProperty;

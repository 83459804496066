import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPropertyType,
  toggleSubPropertyType,
  clearPropertyType,
  clearSubPropertyType
} from '../../../../redux/propertyReducer';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './commercial.css';
import { useTranslation } from 'react-i18next';

const CustomDropdown = ({ label, options = [], onSelect, selectedOptions = [], width, disabled, multiple = false }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(selectedOptions);
  const dropdownRef = React.useRef(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    setSelected(selectedOptions);
  }, [selectedOptions]);

  const handleDropdownToggle = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };


  const handleOptionToggle = (option) => {
    if (disabled) return;
  
    const backendLabel = option.backendLabel;
  
    if (multiple) {
      // Check if the option is already selected by comparing the backendLabel
      const isSelected = selected.some((sel) => sel.backendLabel === backendLabel);
      const newSelected = isSelected
        ? selected.filter((sel) => sel.backendLabel !== backendLabel) // Remove option
        : [...selected, option]; // Add option
      setSelected(newSelected);
      onSelect(newSelected);
    } else {
      setSelected([option]);
      onSelect(option);
      setIsOpen(false);
    }
  };
  
  

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  const displayText = multiple
  ? selected.length > 1
    ? `${t(selected[0].label)} +${selected.length - 1}` // Show translated label + number of additional selections
    : t(selected[0]?.label) || t('Select Property Type')// Show single category translation
  : t(selected[0]?.label) || t('Categories'); // Show


  return (
    <div className="relative" ref={dropdownRef} style={{ width }}>
      <button
        className={`h-10 flex justify-between items-center rounded text-[#737579] font-fifth font-[400] text-base sm:text-sm md:text-[18px] text-customColor bg-white border-2 border-gray-300 truncate ${
          disabled ? 'cursor-not-allowed opacity-50' : ''
        }`}
        style={{ fontWeight: 600, width }}
        onClick={handleDropdownToggle}
        disabled={disabled}
      >
        <span className="flex items-center ml-1">
          {selected[0]?.icon && (
            <img
              src={selected[0].icon}
              alt={selected[0].label}
              className="w-4 h-4 mr-2"
              onError={(e) => (e.target.src = 'https://via.placeholder.com/16')}
            />
          )}
          <span className=" text-xs sm:text-sm md:text-[18px] text-[#737579] font-fifth font-[400]    capitalize">
          {t(displayText)}
          </span>
        </span>
        <KeyboardArrowDownIcon
          style={{
            transition: 'transform 0.3s ease-in-out',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            marginTop: '3px'
          }}
        />
      </button>
      {isOpen && !disabled && (
        <div
          className="absolute bg-white border text-base sm:text-sm md:text-[16px] text-[#737579] font-fifth font-[400] capitalize border-gray-300 rounded shadow-lg"
          style={{ width }}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className={`flex justify-between items-center p-2 hover:bg-gray-100 cursor-pointer ${
                selected.some((sel) => sel.label === option.label) ? 'bg-gray-200' : ''
              }`}
              onClick={() => handleOptionToggle(option)}
            >
              <div className="flex items-center">
                {option.icon && (
                  <img
                    src={option.icon}
                    alt={option.label}
                    className="w-4 h-4"
                    onError={(e) => (e.target.src = 'https://via.placeholder.com/16')}
                  />
                )}
                <div className="text-xs sm:text-sm md:text-base text-[#737579] font-fifth font-[400] ml-2 uppercase mt-1">
                  {t(option.label)}
                </div>
              </div>

              <input
                type="checkbox"
                className="gradient-checkbox"
                checked={selected.some((sel) => sel.label === option.label)}
                onChange={() => handleOptionToggle(option)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const Allfilter = () => {
  const dispatch = useDispatch();
  const propertyType = useSelector((state) => state.properties.propertyType);
  const subPropertyType = useSelector((state) => state.properties.subPropertyType);
  const selectedCity = useSelector((state) => state.location.selectedCity);
  const isTabletScreen = useMediaQuery('(max-width:1024px)');
  const selectedState = useSelector((state) => state.location.selectedState);

  const [defaultSubPropertyTypes, setDefaultSubPropertyTypes] = React.useState([]);

  const Commercial = [
    { label: 'Commercial Shop', backendLabel: 'CommercialShop' },
    { label: 'Commercial Land', backendLabel: 'CommercialLand' },
    { label: 'IT Park', backendLabel: 'CommercialItPark' },
    { label: 'Industrial Land', backendLabel: 'IndustrialLand' }
  ];

  const Residential = [
    { label: 'Flat/Apartment', backendLabel: 'FlatApartment' },
    { label: 'Residential House', backendLabel: 'ResidentialHouse' },
    { label: 'Residential Land Plot', backendLabel: 'ResidentialLandPlot' },
    { label: 'Villa', backendLabel: 'Villa' },
    { label: 'Builder Floor', backendLabel: 'BuilderFloor' }
  ];
  const Other = [
    { label: 'Agriculture', backendLabel: 'Agriculture' },
    { label: 'Farmhouse', backendLabel: 'Farmhouse' }
  ];

  let subOptions = [];
  if (propertyType === 'Commercial') {
    subOptions = Commercial;
  } else if (propertyType === 'Residential') {
    subOptions = Residential;
  } else if (propertyType === 'Other') {
    subOptions = Other;
  }

  const mainOptions = [
    { label: 'Commercial', icon: 'https://squarebric-media-files.squarebric.com/Coomercial.svg' },
    { label: 'Residential', icon: 'https://squarebric-media-files.squarebric.com/Residence.svg' },
    { label: 'Other', icon: 'https://squarebric-media-files.squarebric.com/Other.svg' }
  ];

  const defaultPropertyType = mainOptions.find((option) => option.label === propertyType);
  const selectedSubOptions = subOptions
    .map((option) => ({ label: option.label, backendLabel: option.backendLabel }))
    .filter((option) => subPropertyType.includes(option.backendLabel));

  React.useEffect(() => {
    if (!selectedState || !selectedCity) {
      dispatch(clearPropertyType());
      dispatch(clearSubPropertyType());
    }
  }, [selectedState, selectedCity, dispatch]);

  const handlePropertyTypeChange = (option) => {
    dispatch(setPropertyType(option.label));
  };

  const handleSubPropertyTypeChange = (selectedOptions) => {
    const selectedLabels = selectedOptions.map((option) => option.backendLabel);
    dispatch(toggleSubPropertyType(selectedLabels));
  };

  return (
    <div className="flex flex-row justify-center items-center">
      <div className="w-full">
        <div className="flex justify-center items-center ml-2">
          <CustomDropdown
            label="Categories"
            options={mainOptions}
            onSelect={handlePropertyTypeChange}
            selectedOptions={defaultPropertyType ? [defaultPropertyType] : []}
            width={isTabletScreen ? '200px' : '180px'}
            disabled={!selectedCity}
          />
        </div>
      </div>


      
      <div className="w-full">
        <div className="flex justify-center items-center ml-7">
          <CustomDropdown
            label="Type of Property"
            options={subOptions}
            onSelect={handleSubPropertyTypeChange}
            selectedOptions={selectedSubOptions}
            width={isTabletScreen ? '200px' : '250px'}
            multiple
            disabled={!selectedCity}
          />
        </div>
      </div>
    </div>
  );
};

export default Allfilter;
